import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, Modal, Dimensions, Platform } from 'react-native';
import colors from '../theme/colors';
import { supabase } from '../utils/initSupabase';
import Toast from 'react-native-toast-message';

const { width } = Dimensions.get('window');
const isLargeScreen = width > 767;

const ContributeModal = ({ visible, onClose }) => {
  const [contributorName, setContributorName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (text) => {
    setContactInfo(text);
    if (!validateEmail(text)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleModalSubmit = async () => {
    if (contributorName && contactInfo && !emailError) {
      try {
        const { data, error } = await supabase
          .from('kuwait_contributors')
          .insert({ 
            contact_info: contactInfo,
            name: contributorName
          });

        if (error) throw error;
        Toast.show({
          type: 'success',
          text1: 'Thank you!',
          text2: 'Your information has been submitted successfully.',
          position: 'bottom',
          style: {
            borderLeftColor: colors.primary,
          }
        });
        onClose();
      } catch (error) {
        console.error('Error saving contributor info:', error.message);
        Toast.show({
          type: 'error',
          text1: 'Error',
          text2: 'Failed to submit your information. Please try again.',
          position: 'bottom',
          style: {
            borderLeftColor: colors.darkRed,
          }
        });
      }
    } else {
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'Please fill in both name and a valid email address.',
        position: 'bottom',
        style: {
          borderLeftColor: colors.darkRed,
        }
      });
    }
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Contribute to our project!</Text>
          <Text style={styles.modalText}>As a Kuwaiti, would you like to help improve our app? Your knowledge of local words and phrases is invaluable! Please enter your name and email to join our community of contributors:</Text>
          {/* <Text style={styles.modalSubText}>By contributing, you'll help preserve Kuwaiti culture and language, and make our app even better for everyone!</Text> */}
          <TextInput
            style={[styles.modalInput, Platform.OS === 'web' && styles.webInput]}
            onChangeText={setContributorName}
            value={contributorName}
            placeholder="Your Name(optional)"
          />
          <TextInput
            style={[styles.modalInput, emailError ? styles.inputError : null, Platform.OS === 'web' && styles.webInput]}
            onChangeText={handleEmailChange}
            value={contactInfo}
            placeholder="Email Address(optional)"
            keyboardType="email-address"
          />
          {emailError ? <Text style={styles.errorText}>{emailError}</Text> : null}
          <TouchableOpacity style={styles.modalButton} onPress={handleModalSubmit}>
            <Text style={styles.buttonText}>Submit</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.modalCloseButton} onPress={onClose}>
            <Text style={styles.modalCloseButtonText}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: isLargeScreen ? '30%' : '90%',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
    color: colors.primary,
    fontFamily: 'KhebratMusamim',
    },
  modalSubText: {
    fontSize: 16,
    marginBottom: 15,
    textAlign: 'center',
    color: colors.text,
    fontFamily: 'KhebratMusamim',
  },
  modalText: {
    fontSize: 16,
    marginBottom: 15,
    textAlign: 'center',
    color: colors.text,
    fontFamily: 'KhebratMusamim',
  },
  modalInput: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 10,
    padding: 10,
    width: '100%',
    marginBottom: 15,
    fontFamily: 'KhebratMusamim',
  },
  modalButton: {
    backgroundColor: colors.primary,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 10,
  },
  modalCloseButton: {
    marginTop: 10,
  },
  modalCloseButtonText: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: 'KhebratMusamim',
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'KhebratMusamim',
  },
  inputError: {
    borderColor: colors.darkRed,
  },
  errorText: {
    color: colors.darkRed,
    fontSize: 14,
    marginTop: -10,
    marginBottom: 10,
    fontFamily: 'KhebratMusamim',
  },
  webInput: {
    outlineWidth: 0,
    outlineColor: 'transparent',
    outlineStyle: 'none',
  }
});

export default ContributeModal;