import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCzOl2xaiH5D0bJAgYh3NC-sc939PgXS3I",
  authDomain: "kuwaiti-words.firebaseapp.com",
  projectId: "kuwaiti-words",
  storageBucket: "kuwaiti-words.appspot.com",
  messagingSenderId: "980045802477",
  appId: "1:980045802477:web:19ebab31c803ba701395e4",
  measurementId: "G-JWTVNFYM9S"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logEvent = async (eventName, params) => {
  firebaseLogEvent(analytics, eventName, params);
};

export const setUserProperties = async (properties) => {
  // Implement if needed for web
};

export default app;