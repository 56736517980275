import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import LottieView from 'lottie-react-native';
import colors from '../theme/colors';

const SplashScreen = ({ onFinish }) => {
  const animation = useRef(null);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      animation.current?.play();
    }
  }, []);

  return (
    <View style={styles.container}>
      {Platform.OS !== 'web' ? (
        <LottieView
          ref={animation}
          source={require('../../assets/splash-animation.json')}
          autoPlay={Platform.OS === 'web'}
          loop={false}
          style={styles.animation}
          onAnimationFinish={onFinish}
        />
      ) : (
        <View style={styles.webPlaceholder} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondary,
  },
  animation: {
    width: 200,
    height: 200,
  },
  webPlaceholder: {
    width: 200,
    height: 200,
    backgroundColor: colors.primary,
  },
});

export default SplashScreen;