import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import packs from '../data/packs'; // Import your packs data

export const usePackData = (packId) => {
  const [pack, setPack] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackData = async () => {
      try {
        if (!packId) {
          throw new Error('No pack ID provided');
        }

        // First, try to get the pack from the imported data
        let foundPack = packs.find(p => p.id === packId);

        // If not found, try to get it from AsyncStorage
        if (!foundPack) {
          const storedPack = await AsyncStorage.getItem(`pack_${packId}`);
          if (storedPack) {
            foundPack = JSON.parse(storedPack);
          }
        }

        if (foundPack) {
          setPack(foundPack);
          // Store the pack in AsyncStorage for future use
          await AsyncStorage.setItem(`pack_${packId}`, JSON.stringify(foundPack));
        } else {
          throw new Error('Pack not found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackData();
  }, [packId]);

  return { pack, isLoading, error };
};