import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../theme/colors';

const { width: SCREEN_WIDTH } = Dimensions.get('window');

const isWideScreen = () => SCREEN_WIDTH > 900;

const HeroSection = ({ title, category, count }) => {
  return (
    <View style={styles.container}>
      <Text style={[styles.title, isWideScreen() && styles.titleWide]}>{title}</Text>
      <View style={styles.infoContainer}>
        <MaterialIcons name="category" size={isWideScreen() ? 30 : 18} color={colors.primary} />
        <Text style={[styles.info, isWideScreen() && styles.infoWide]}>{category}</Text>
      </View>
      <View style={styles.infoContainer}>
        <MaterialIcons name="format-list-numbered" size={isWideScreen() ? 22 : 18} color={colors.primary} />
        <Text style={[styles.info, isWideScreen() && styles.infoWide]}>
          {count} {count === 1 ? 'item' : 'items'}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.secondary,
    padding: 20,
    paddingVertical: 100,
    alignItems: 'center',
  },
  title: {
    fontFamily: 'KhebratMusamim',
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 10,
  },
  titleWide: {
    fontSize: 36,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  info: {
    fontFamily: 'KhebratMusamim',
    marginLeft: 5,
    fontSize: 22,
    color: colors.primary,
  },
  infoWide: {
    fontSize: 22,
  },
});

export default HeroSection;