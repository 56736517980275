import React, { useEffect } from 'react';
import { View, StyleSheet, Text, ImageBackground, Image, SafeAreaView, StatusBar, Platform, TouchableOpacity, Linking } from 'react-native';
import { Zocial } from '@expo/vector-icons';
import PackSwiper from '../components/PackSwiper';
import packs from '../data/packs';
import colors from '../theme/colors';
import { logEvent } from '../utils/firebase';

const HomeScreen = ({ navigation }) => {
  useEffect(() => {
    logEvent('screen_view', { screen_name: 'Home' });
  }, []);

  const handlePackPress = (pack) => {
    logEvent('pack_selected', { pack_id: pack.id, pack_title: pack.title });
    navigation.navigate('PackDetail', { packId: pack.id }); // Change packId to a single value
  };

  const handleInstagramPress = () => {
    logEvent('instagram_link_clicked');
    Linking.openURL('https://www.instagram.com/kuwaitiwords');
  };

  const handleContributePress = () => {
    logEvent('contribute_link_clicked');
    navigation.navigate('WordContribution');
  };

  return (
    <ImageBackground 
      source={require('../../assets/light-bg.jpg')} 
      style={styles.backgroundImage}
    >
      <SafeAreaView style={styles.container}>
        <View style={styles.navbar}>
          <Image 
            source={require('../../assets/dark-logo.png')}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View style={styles.content}>
          <PackSwiper packs={packs} onPackPress={handlePackPress} />
          
          <View style={styles.bottomContent}>
          <TouchableOpacity onPress={handleContributePress} style={styles.contributeLink}>
              <Text style={styles.contributeLinkText}>Yalla Contribute to our words collection!🇰🇼</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={handleInstagramPress} style={styles.instagramButton}>
              <Zocial style={styles.instagramIcon} name="instagram" size={24} color={colors.primary} />
              <Text style={styles.instagramText}>@kuwaitiwords</Text>
            </TouchableOpacity>

          </View>
        </View>
      </SafeAreaView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 1,
  },
  navbar: {
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  logo: {
    height: 300,
    width: '80%',
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomContent: {
    alignItems: 'center',
    marginBottom: 20,
  },
  instagramButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  instagramText: {
    marginLeft: 10,
    fontSize: 24,
    color: colors.primary,
    fontFamily: 'KhebratMusamim',
  },
  instagramIcon: {
    marginBottom: 6,
  },
  contributeLink: {
    marginTop: 10,
    // padding: 10,
  },
  contributeLinkText: {
    fontSize: 16,
    color: colors.primary,
    fontFamily: 'KhebratMusamim',
    textDecorationLine: 'underline',
  },
});

export default HomeScreen;
