import React from 'react';
import { TouchableOpacity, Image, View, StyleSheet, Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import HomeScreen from '../screens/HomeScreen';
import PackDetailScreen from '../screens/PackDetailScreen';
import LearningScreen from '../screens/LearningScreen';
import colors from '../theme/colors';
import WordContributionScreen from '../screens/WordContributionScreen';
import { CommonActions } from '@react-navigation/native';

const Stack = createStackNavigator();


const AppNavigator = () => {
  const navigation = useNavigation();
  
  const goToHome = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'Home' }],
      })
    );
  };
  
  const LogoTitle = () => (
    <TouchableOpacity onPress={goToHome}>
      <Image
        style={styles.logo}
        source={require('../../assets/dark-logo.png')}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={({ navigation, route }) => ({
        headerShown: true,
        headerTitle: () => <LogoTitle />,
        headerTitleAlign: 'center',
        headerLeft: () => {
          if (route.name !== 'Home') {
            return (
              <TouchableOpacity 
                activeOpacity={0.8} 
                onPress={() => {
                  if (navigation.canGoBack()) {
                    navigation.goBack();
                  } else {
                    goToHome();
                  }
                }} 
                style={styles.backButton}
              >
                <Ionicons name="arrow-back" size={24} color={colors.primary} />
              </TouchableOpacity>
            );
          }
          return null;
        },
        headerStyle: {
          backgroundColor: colors.secondary,
          height: 100, // Adjust this value to match your logo's height
        },
        headerTintColor: colors.primary,
      })}
    >
      <Stack.Screen 
        name="Home" 
        component={HomeScreen} 
        options={{ headerShown: false }}
      />
      <Stack.Screen name="PackDetail" component={PackDetailScreen} />
      <Stack.Screen name="Learning" component={LearningScreen} />
      <Stack.Screen name="WordContribution" component={WordContributionScreen} />
    </Stack.Navigator>
  );
};

const styles = StyleSheet.create({
  logo: {
    width: 150, // Adjust this value based on your logo's dimensions
    height: 150, // Adjust this value based on your logo's dimensions
  },
  backButton: {
    marginLeft: 16,
  },
});

export default AppNavigator;