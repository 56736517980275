import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Audio } from 'expo-av';
import { Ionicons } from '@expo/vector-icons';
import Slider from '@react-native-community/slider';
import colors from '../theme/colors';

const AudioPlayer = ({ audioUri }) => {
  const [sound, setSound] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState(0);
  const [isEnded, setIsEnded] = useState(false);

  const soundRef = useRef(null);

  useEffect(() => {
    return sound
      ? () => {
          console.log('Unloading Sound');
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  useEffect(() => {
    if (audioUri) {
      loadSound();
    }
  }, [audioUri]);

  const loadSound = async () => {
    console.log('Loading Sound');
    const { sound } = await Audio.Sound.createAsync(
      { uri: audioUri },
      { shouldPlay: false },
      onPlaybackStatusUpdate
    );
    setSound(sound);
    soundRef.current = sound;
  };

  const onPlaybackStatusUpdate = (status) => {
    if (status.isLoaded) {
      setDuration(status.durationMillis);
      setPosition(status.positionMillis);
      setIsPlaying(status.isPlaying);
      setIsEnded(status.didJustFinish);
    }
  };

  const playPause = async () => {
    if (sound) {
      if (isPlaying) {
        await sound.pauseAsync();
      } else {
        if (isEnded) {
          await sound.setPositionAsync(0);
          setIsEnded(false);
        }
        await sound.playAsync();
      }
    }
  };

  const onSeek = async (value) => {
    if (sound) {
      await sound.setPositionAsync(value);
    }
  };

  const getMMSSFromMillis = (millis) => {
    const totalSeconds = millis / 1000;
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor(totalSeconds / 60);

    const padWithZero = (number) => {
      const string = number.toString();
      if (number < 10) {
        return '0' + string;
      }
      return string;
    };
    return padWithZero(minutes) + ':' + padWithZero(seconds);
  };

  return (
    <View style={styles.audioPlayerContainer}>
      <TouchableOpacity onPress={playPause} style={styles.playPauseButton}>
        <Ionicons
          name={isPlaying ? 'pause' : 'play'}
          size={24}
          color={colors.primary}
        />
      </TouchableOpacity>
      <View style={styles.sliderContainer}>
        <View style={styles.sliderBackground} />
        <Slider
          style={styles.slider}
          minimumValue={0}
          maximumValue={duration}
          value={position}
          onSlidingComplete={onSeek}
          minimumTrackTintColor={colors.primary}
          maximumTrackTintColor="transparent"
          thumbTintColor={colors.primary}
        />
        <View style={styles.timeContainer}>
          <Text style={styles.timeText}>{getMMSSFromMillis(position)}</Text>
          <Text style={styles.timeText}>{getMMSSFromMillis(duration)}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  audioPlayerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    backgroundColor: colors.secondary,
    borderRadius: 8,
    padding: 10,
  },
  playPauseButton: {
    marginRight: 10,
  },
  sliderContainer: {
    flex: 1,
    position: 'relative',
  },
  sliderBackground: {
    position: 'absolute',
    top: '30%',
    left: 0,
    right: 0,
    height: 2,
    backgroundColor: '#D3D3D3', // Grayish line color
    zIndex: 1,
  },
  slider: {
    width: '100%',
    zIndex: 2,
  },
  timeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
  },
  timeText: {
    fontSize: 12,
    color: colors.primary,
  },
});

export default AudioPlayer;