const colors = {
  primary: '#cbac95',
  secondary: '#f2eee7',
  text: {
    primary: '#cbac95',
    secondary: '#f2eee7',
  },
  background: {
    primary: '#f2eee7',
    secondary: '#ffffff',
  },
  accent: '#a88c76',
  gray: '#808080',
    black: '#000000',
  white: '#ffffff',
  darkRed: '#8B0000',
};

export default colors;