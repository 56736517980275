import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, ScrollView, FlatList, Modal, Platform, Alert, ActivityIndicator } from 'react-native';
import { Audio as ExpoAudio } from 'expo-av';
import colors from '../theme/colors';
import { supabase } from '../utils/initSupabase';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AudioPlayer from '../components/AudioPlayer';

const WordContributionScreen = () => {
  const [formData, setFormData] = useState({
    id: null,
    arabic: '',
    transliteration: '',
    translation: '',
    exampleSentence: '',
    contributorEmail: '',
  });
  const [recording, setRecording] = useState();
  const [sound, setSound] = useState();
  const [audioUri, setAudioUri] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [submittedWords, setSubmittedWords] = useState([]);
  const [previewSound, setPreviewSound] = useState();
  const [focusedInput, setFocusedInput] = useState(null);
  const [webRecorder, setWebRecorder] = useState(null);
  const [userName, setUserName] = useState('none');
  const [showNameModal, setShowNameModal] = useState(false);
  const [tempName, setTempName] = useState('');
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const initScreen = async () => {
      await setNewOnboardingComplete();
      await loadUserName();
      await checkFirstVisit();
    };

    initScreen();
    return sound
      ? () => {
          console.log('Unloading Sound');
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  const loadUserName = async () => {
    try {
      const storedName = await AsyncStorage.getItem('userName');
      if (storedName) {
          setUserName(storedName);
          fetchSubmittedWords(storedName);

      }
    } catch (error) {
      console.error('Error loading user name:', error);
    }
  };

  const setNewOnboardingComplete = async () => {
    try {
     const result = await AsyncStorage.setItem('newonboardingCompletse', 'true');
     console.log('newOnboardingComplete', result);
    } catch (error) {
      console.error('Error checking first visit:', error);
    }
  };

  const checkFirstVisit = async () => {
    try {
      const hasVisited = await AsyncStorage.getItem('hasVisitedWordContribution');
      if (hasVisited === null) {
        setShowIntroModal(true);
      }
    } catch (error) {
      console.error('Error checking first visit:', error);
    }
  };

  const closeIntroModal = async () => {
    setShowIntroModal(false);
    try {
      await AsyncStorage.setItem('hasVisitedWordContribution', 'true');
    } catch (error) {
      console.error('Error saving visit status:', error);
    }
  };

  const handleNameSubmit = async () => {
    if (tempName.trim()) {
      try {
        await AsyncStorage.setItem('userName', tempName.trim());
        setUserName(tempName.trim());
        setShowNameModal(false);
        handleSubmit(); // Call handleSubmit immediately after setting the name
      } catch (error) {
        console.error('Error saving user name:', error);
        alert('Error saving your name. Please try again.');
      }
    } else {
      alert('Please enter a valid name.');
    }
  };

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  async function startRecording() {
    if (Platform.OS === 'web') {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);
        const chunks = [];

        recorder.ondataavailable = (e) => chunks.push(e.data);
        recorder.onstop = async () => {
          const blob = new Blob(chunks, { type: 'audio/mp3' });
            const uri = URL.createObjectURL(blob);
            console.log('Recording stopped and stored at', uri);
          setAudioUri(uri);
        };

        recorder.start();
        setWebRecorder(recorder);
        setRecordingDuration(0);
        const interval = setInterval(() => {
          setRecordingDuration((prev) => prev + 1);
        }, 1000);
        recorder.interval = interval;
      } catch (err) {
        console.error('Failed to start recording', err);
        alert(`Recording failed: ${err.message || 'Unknown error'}`);
      }
    } else {
      // Existing Expo AV implementation for native platforms
      try {
        console.log('Requesting permissions..');
        await ExpoAudio.requestPermissionsAsync();
        await ExpoAudio.setAudioModeAsync({
          allowsRecordingIOS: true,
          playsInSilentModeIOS: true,
        });

        console.log('Starting recording..');
        const recording = new ExpoAudio.Recording();
        await recording.prepareToRecordAsync(ExpoAudio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY);
        await recording.startAsync();
        setRecording(recording);
        setRecordingDuration(0);
        const interval = setInterval(() => {
          setRecordingDuration((prev) => prev + 1);
        }, 1000);
        recording.interval = interval;
        console.log('Recording started');
      } catch (err) {
        console.error('Failed to start recording', err);
        alert(`Recording failed: ${err.message || 'Unknown error'}`);
      }
    }
  }

  async function stopRecording() {
    if (Platform.OS === 'web') {
      if (webRecorder) {
        clearInterval(webRecorder.interval);
        setRecordingDuration(0);
        webRecorder.stop();
        setWebRecorder(null);
      }
    } else {
      // Existing Expo AV implementation for native platforms
      console.log('Stopping recording..');
      if (!recording) {
        console.log('No recording to stop');
        return;
      }
      clearInterval(recording.interval);
      setRecordingDuration(0);
      try {
        await recording.stopAndUnloadAsync();
        const uri = recording.getURI();
        setAudioUri(uri);
        console.log('Recording stopped and stored at', uri);

      } catch (error) {
        console.error('Failed to stop recording', error);
      }
      setRecording(undefined);
    }
  }

  async function playSound() {
    console.log('Loading Sound');
    const { sound } = await ExpoAudio.Sound.createAsync({ uri: audioUri });
    setSound(sound);

    console.log('Playing Sound');
    await sound.playAsync();
  }

  async function playPreviewSound() {
    if (Platform.OS === 'web') {
      if (audioUri) {
        // Check if it's Safari
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        if (isSafari) {
          // For Safari, we'll use an audio element
          const audioElement = document.createElement('audio');
          audioElement.src = audioUri;
          audioElement.play().catch(error => {
            console.error('Error playing audio in Safari:', error);
            alert('Error playing audio. Please try again or use a different browser.');
          });
        } else {
          // For other browsers, use the Audio API
          const audio = new Audio(audioUri);
          console.log('Playing Sound', audioUri);
          audio.play().catch(error => {
            console.error('Error playing audio:', error);
            alert('Error playing audio. Please try again.');
          });
        }
      }
    } else {
      // Existing Expo AV implementation for native platforms
      if (previewSound) {
        await previewSound.unloadAsync();
      }
      if (!audioUri) {
        console.log('No audio to play');
        return;
      }
      console.log('Loading Sound');
      try {
        const { sound } = await ExpoAudio.Sound.createAsync({ uri: audioUri });
        setPreviewSound(sound);
        console.log('Playing Sound');
        await sound.playAsync();
      } catch (error) {
        console.error('Failed to play sound', error);
        alert('Error playing audio. Please try again.');
      }
    }
  }

    const fetchSubmittedWords = async (name = null) => {
    const { data, error } = await supabase
      .from('word_contributions')
      .select('*')
      .eq('contributor_name', name) // Only fetch words for the current user
      .order('created_at', { ascending: false });
    if (error) {
      console.error('Error fetching submitted words:', error);
    } else {
      setSubmittedWords(data);
    }
  };

  const uploadAudioToSupabase = async (audioUri) => {
    try { 
      
      let audioBlob;
      const audioFile = await fetch(audioUri);
      audioBlob = await audioFile.blob();
      let uploadData;
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        const arrayBuffer = await new Response(audioBlob).arrayBuffer();
        uploadData = arrayBuffer;
      } else {
        uploadData = audioBlob;
      }

      const filename = `audio_${Date.now()}.m4a`;

      const { data, error } = await supabase.storage
        .from('word-contributions')
        .upload(filename, uploadData, {
          contentType: 'audio/m4a',
          cacheControl: '3600',
        });

      console.log('Audio upload result:', data, error);

      if (error) throw error;

      const { data: publicUrlData } = supabase.storage
        .from('word-contributions')
        .getPublicUrl(filename);

      return publicUrlData.publicUrl;
    } catch (error) {
      console.error('Error uploading audio:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    const currentUserName = await AsyncStorage.getItem('userName');
    if (!currentUserName) {
      setShowNameModal(true);
      return;
    }

    // Validate required fields
    if (!formData.arabic.trim() || !formData.transliteration.trim() || !formData.translation.trim()) {
      alert('Please fill in the Arabic word, transliteration, and translation fields.');
      return;
    }

    setIsSubmitting(true);

    try {
      let audioUrl = null;
      if (audioUri) {
        audioUrl = await uploadAudioToSupabase(audioUri);
      }

      const contributionData = {
        arabic: formData.arabic,
        transliteration: formData.transliteration,
        translation: formData.translation,
        example_sentence: formData.exampleSentence,
        contributor_name: currentUserName,
        contributor_email: formData.contributorEmail,
        audio_url: audioUrl,
      };

      // Remove null or undefined values
      Object.keys(contributionData).forEach(key => 
        (contributionData[key] === null || contributionData[key] === undefined) && delete contributionData[key]
      );

      console.log('Submitting data:', contributionData);

      let result;
      if (formData.id) {
        result = await supabase
          .from('word_contributions')
          .update(contributionData)
          .eq('id', formData.id);
      } else {
        result = await supabase
          .from('word_contributions')
          .insert([contributionData]);
      }

      if (result.error) throw result.error;
      
      setIsSubmitting(false);
      alert(formData.id ? 'Word contribution updated successfully!' : 'Word contribution submitted successfully!');
      setFormData({
        id: null,
        arabic: '',
        transliteration: '',
        translation: '',
        exampleSentence: '',
        contributorEmail: '',
      });
      setAudioUri(null);
      await fetchSubmittedWords(currentUserName);
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error submitting word contribution:', error);
      alert('Error submitting word contribution: ' + (error.message || 'Unknown error'));
    }
  };

  const handleEdit = async (id) => {
    const wordToEdit = submittedWords.find(word => word.id === id);
    setFormData({
      id: wordToEdit.id,
      arabic: wordToEdit.arabic,
      transliteration: wordToEdit.transliteration,
      translation: wordToEdit.translation,
      exampleSentence: wordToEdit.example_sentence,
      contributorEmail: wordToEdit.contributor_email,
    });
    setAudioUri(wordToEdit.audio_url);
  };

  const handleNewWord = () => {
    setFormData({
      id: null,
      arabic: '',
      transliteration: '',
      translation: '',
      exampleSentence: '',
      contributorEmail: '',
    });
    setAudioUri(null);
  };

  const renderWordItem = ({ item }) => (
    <View key={item.id.toString()} style={styles.wordItem}>
      <Text style={styles.wordText}>{item.arabic} - {item.translation}</Text>
      <TouchableOpacity onPress={() => handleEdit(item.id)}>
        <Text style={styles.editButton}>Edit</Text>
      </TouchableOpacity>
    </View>
  );

  const renderInstructions = () => (
    <View style={styles.instructionsContainer}>
      <Text style={styles.instructionsTitle}>Word Contribution Ideas:</Text>
      <Text style={styles.instructionText}>• Common everyday expressions</Text>
      <Text style={styles.instructionText}>• Unique Kuwaiti slang</Text>
      <Text style={styles.instructionText}>• Traditional sayings or proverbs</Text>
      <Text style={styles.instructionText}>• Words related to Kuwaiti culture or cuisine</Text>
      <Text style={styles.instructionText}>• Modern colloquialisms popular among youth</Text>
    </View>
  );

  const renderRecordingGuidelines = () => (
    <View style={styles.guidelinesContainer}>
      <Text style={styles.guidelinesTitle}>Recording Guidelines:</Text>
      <Text style={styles.guidelineText}>1. Speak clearly and at a normal pace.</Text>
      <Text style={styles.guidelineText}>2. Pronounce the word, then use it in a sentence.</Text>
      <Text style={styles.guidelineText}>3. Leave a brief pause between the word and sentence.</Text>
      <Text style={styles.guidelineText}>4. Keep background noise to a minimum.</Text>
      <Text style={styles.guidelineText}>5. Try to keep your recording under 15 seconds.</Text>
    </View>
  );

  const renderTextInput = (name, placeholder, multiline = false) => (
    <TextInput
      style={[
        styles.input,
        multiline && styles.textArea,
        focusedInput === name && styles.focusedInput,
        Platform.OS === 'web' && styles.webInput
      ]}
      placeholder={placeholder}
      placeholderTextColor="#808080"
      value={formData[name]}
      onChangeText={(text) => handleChange(name, text)}
      onFocus={() => setFocusedInput(name)}
      onBlur={() => setFocusedInput(null)}
      multiline={multiline}
      numberOfLines={multiline ? 3 : 1}
    />
  );

  const renderAudioElement = () => {
    if (Platform.OS === 'web' && audioUri) {
      return (
        <audio
          src={audioUri}
          controls
          style={{ width: '100%', marginTop: 10 }}
        >
          Your browser does not support the audio element.
        </audio>
      );
    }else if (audioUri) {
      return <AudioPlayer audioUri={audioUri} />;
    }
    return null;
  };

  const renderNameModal = () => (
    <Modal
      animationType="fade"
      transparent={true}
      visible={showNameModal}
      onRequestClose={() => setShowNameModal(false)}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>Welcome to Word Contributions!</Text>
          <Text style={styles.modalText}>
            Please enter your name. This will be associated with your word contributions.
          </Text>
          <TextInput
            style={[styles.modalInput, Platform.OS === 'web' && styles.webInput]}
            placeholder="Enter your name"
            value={tempName}
            onChangeText={setTempName}
          />
          <TouchableOpacity style={styles.modalButton} onPress={handleNameSubmit}>
            <Text style={styles.buttonText}>Submit</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );

  const renderIntroModal = () => (
    <Modal
      animationType="fade"
      transparent={true}
      visible={showIntroModal}
      onRequestClose={closeIntroModal}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>Welcome to Word Contributions!</Text>
          <ScrollView>
            <Text style={styles.modalText}>
              As a proud Kuwaiti, you have the unique opportunity to contribute to the preservation and promotion of our beautiful language.
            </Text>
            <Text style={styles.modalText}>
              Your contributions will:
            </Text>
            <Text style={styles.modalListItem}>• Help future generations learn and appreciate Kuwaiti Arabic</Text>
            <Text style={styles.modalListItem}>• Assist non-native speakers in understanding our rich culture</Text>
            <Text style={styles.modalListItem}>• Create a valuable resource for linguists and language enthusiasts</Text>
            <Text style={styles.modalListItem}>• Preserve unique Kuwaiti expressions and slang</Text>
            <Text style={styles.modalText}>
              Every word you contribute is a step towards keeping our language alive and thriving. Your knowledge and input are invaluable!
            </Text>
          </ScrollView>
          <TouchableOpacity style={styles.modalButton} onPress={closeIntroModal}>
            <Text style={styles.buttonText}>Let's Get Started!</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );

  return (
    <ScrollView style={styles.container}>
      {renderIntroModal()}
      {renderNameModal()}
      <Text style={styles.title}>Contribute a Kuwaiti Word</Text>
      {userName !== 'none' && <Text style={styles.subtitle}>Welcome, {userName}!</Text>}
      
      <View style={styles.innerContainer}>
        {renderInstructions()}

        {renderTextInput('arabic', 'Arabic Word (e.g. شنو)')}
        {renderTextInput('transliteration', 'Transliteration (e.g. shno)')}
        {renderTextInput('translation', 'Translation (e.g. What)')}
        {renderTextInput('exampleSentence', 'Example Sentence (e.g. شنو هذا؟ - What is this?)', true)}
        {renderTextInput('contributorEmail', 'Your Email (optional)')}
        {renderRecordingGuidelines()}

        <View style={styles.audioContainer}>
          <TouchableOpacity
            style={[styles.button, styles.audioButton]}
            onPress={recording || webRecorder ? stopRecording : startRecording}
          >
            <Text style={styles.buttonText}>
              {recording || webRecorder ? `Stop (${recordingDuration}s)` : 'Start Recording'}
            </Text>
          </TouchableOpacity>
          {audioUri && (
            <TouchableOpacity
              style={[styles.button, styles.audioButton]}
              onPress={playPreviewSound}
            >
              <Text style={styles.buttonText}>Play Recording</Text>
            </TouchableOpacity>
          )}
        </View>
        
        {renderAudioElement()}

        <View style={styles.buttonContainer}>
          <TouchableOpacity 
            style={[styles.button, styles.submitButton, isSubmitting && styles.disabledButton]} 
            onPress={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <ActivityIndicator color={colors.secondary} />
            ) : (
              <Text style={styles.buttonText}>
                {formData.id ? 'Update Contribution' : 'Submit Contribution'}
              </Text>
            )}
          </TouchableOpacity>
          {formData.id && (
            <TouchableOpacity 
              style={[styles.button, styles.newWordButton]} 
              onPress={handleNewWord}
              disabled={isSubmitting}
            >
              <Text style={styles.buttonText}>New Word</Text>
            </TouchableOpacity>
          )}
        </View>
        
        <Text style={styles.subtitle}>Your Submitted Words</Text>
        <ScrollView style={styles.wordList}>
          {submittedWords.map((item) => (
            renderWordItem({ item })
          ))}
        </ScrollView>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: colors.secondary,
    },
  innerContainer: {
      width: '100%',
      maxWidth: 600,
    marginHorizontal: 'auto',    
  },
    title: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 20,
    textAlign: 'center',
  },
    input: {
      fontFamily: 'KhebratMusamim',
    backgroundColor: colors.secondary,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    fontSize: 16,
    color: colors.primary,
    borderWidth: 2,
    borderColor: colors.primary,
  },
  textArea: {
    height: 100,
    textAlignVertical: 'top',
  },
  instructionsContainer: {
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,
  },
  instructionsTitle: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.white,
    marginBottom: 10,
  },
  instructionText: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 18,
    color: colors.white,
    marginBottom: 5,
  },
    guidelinesContainer: {
    fontFamily: 'KhebratMusamim',  
    backgroundColor: colors.primary,
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  guidelinesTitle: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.white,
    marginBottom: 10,
  },
  guidelineText: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 18,
    color: colors.white,
    marginBottom: 5,
  },
    button: {
    fontFamily: 'KhebratMusamim',  
    backgroundColor: colors.primary,
    padding: 15,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 10,
  },
  buttonText: {
    fontFamily: 'KhebratMusamim',  
    color: colors.secondary,
    fontSize: 18,
    fontWeight: 'bold',
  },
  audioContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  audioButton: {
    flex: 1,
      marginHorizontal: 5,
    backgroundColor: "#D32F2F",
  },
    subtitle: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.primary,
    marginTop: 20,
      marginBottom: 10,
    textAlign: 'center',
  },
  wordList: {
    marginTop: 10,
    paddingBottom: 100,
  },
  wordItem: {
    fontFamily: 'KhebratMusamim',  
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.primary,
  },
    wordText: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 18,
    color: colors.primary,
  },
    editButton: {
    fontFamily: 'KhebratMusamim',  
    color: colors.primary,
    fontWeight: 'bold',
  },
  modalView: {
    margin: 20,
    backgroundColor: colors.secondary,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalTitle: {
    fontFamily: 'KhebratMusamim',  
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
    color: colors.primary,
  },
  focusedInput: {
    fontFamily: 'KhebratMusamim',  
      borderColor: colors.primary,
      borderWidth: 2,
  },
  webInput: {
    fontFamily: 'KhebratMusamim',  
    outline: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
    outlineStyle: 'none',
    },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: colors.secondary,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80%',
    maxWidth: 400,
    maxHeight: '80%',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 15,
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    color: colors.primary,
  },
  modalInput: {
    width: '100%',
    borderColor: colors.primary,
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
    color: colors.primary,
  },
  modalButton: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: 10,
    elevation: 2,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  submitButton: {
    flex: 1,
    marginRight: 5,
  },
  newWordButton: {
    flex: 1,
    marginLeft: 5,
    // backgroundColor: colors.accent, // Add an accent color to your colors theme
  },
  modalListItem: {
    fontFamily: 'KhebratMusamim',
    fontSize: 16,
    color: colors.primary,
    marginLeft: 20,
    marginBottom: 10,
  },
  modalScrollView: {
    marginBottom: 20,
  },
  disabledButton: {
    opacity: 0.7,
  },
});

export default WordContributionScreen;