
import 'react-native-url-polyfill/auto'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.EXPO_PUBLIC_SUPABASE_URL || "https://dsupsovlqjtqpmjkdndj.supabase.co";
const supabaseAnonKey = process.env.EXPO_PUBLIC_SUPABASE_ANON_KEY || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRzdXBzb3ZscWp0cXBtamtkbmRqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY4MjQ3OTIsImV4cCI6MjA0MjQwMDc5Mn0.n21_6nJVs_AGrPWMtom-H-hyc1kxUL1Dor2UCMQutAo";

export const supabase = createClient(
  supabaseUrl,
  supabaseAnonKey,
  {
    auth: {
      storage: AsyncStorage,
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: false,
    },
  })
        