import React from 'react';
import { View, StyleSheet, Dimensions, ScrollView } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import PackCard from './PackCard';

const { width } = Dimensions.get('window');

const PackSwiper = ({ packs, onPackPress }) => {
  const isLargeScreen = width > 900;

  const renderItem = ({ item: pack }) => (
    <View style={styles.slide}>
      <PackCard
        title={pack.title}
        category={pack.category}
        count={pack.count}
        onPress={() => onPackPress(pack)}
      />
    </View>
  );

  const cardSpacing = isLargeScreen ? 20 : 0;
  const containerPadding = isLargeScreen ? 40 : 0;
  const availableWidth = width - (containerPadding * 2);
  const itemWidth = isLargeScreen
    ? (availableWidth / 5) - (cardSpacing * 4 / 5)
    : width * 0.8;

  if (isLargeScreen) {
    const rows = Math.ceil(packs.length / 5);
    return (
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        {[...Array(rows)].map((_, rowIndex) => (
          <View key={rowIndex} style={styles.row}>
            {packs.slice(rowIndex * 5, (rowIndex + 1) * 5).map((pack, index) => (
              <View key={pack.id} style={[styles.slide, { width: itemWidth, marginRight: index < 4 ? cardSpacing : 0 }]}>
                <PackCard
                  title={pack.title}
                  category={pack.category}
                  count={pack.count}
                  onPress={() => onPackPress(pack)}
                />
              </View>
            ))}
          </View>
        ))}
      </ScrollView>
    );
  }

  return (
    <View style={styles.container}>
      <Carousel
        data={packs}
        renderItem={renderItem}
        sliderWidth={availableWidth}
        itemWidth={itemWidth}
        inactiveSlideScale={0.9}
        inactiveSlideOpacity={0.7}
        containerCustomStyle={styles.carouselContainer}
        contentContainerCustomStyle={styles.carouselContentContainer}
        loop={true}
        activeSlideAlignment="center"
        layout="default"
        useScrollView={false}
        enableMomentum={true}
        decelerationRate={0.9}
        enableSnap={true}
        snapToAlignment="center"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollViewContent: {
    padding: 40,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  carouselContainer: {
    flex: 1,
  },
  carouselContentContainer: {
    alignItems: 'center',
  },
  slide: {
    width: '100%',
    height: Dimensions.get('window').height * 0.8,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default PackSwiper;