import { useEffect, useState } from 'react';
import * as Font from 'expo-font';

export default function useFonts() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    async function loadFonts() {
      await Font.loadAsync({
        'AlHurraBold': require('../../assets/fonts/AlHurraTxtBold.ttf'),
        'KhebratMusamim': require('../../assets/fonts/18KhebratMusamimRegular.ttf'),
      });
      setFontsLoaded(true);
    }

    loadFonts();
  }, []);

  return fontsLoaded;
}