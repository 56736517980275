import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, ScrollView, SafeAreaView, Modal, TextInput, Dimensions, Platform } from 'react-native';
import colors from '../theme/colors';
import { supabase } from '../utils/initSupabase';
import WelcomeScreen from './WelcomeScreen';
import { Ionicons } from '@expo/vector-icons';
import Toast from 'react-native-toast-message';
import ContributeModal from '../components/ContributeModal';
import { logEvent } from '../utils/firebase';

const { width } = Dimensions.get('window');
const isLargeScreen = width > 767;

const OnboardingScreen = ({ onFinish }) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [step, setStep] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    logEvent('onboarding_started', {});
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      logEvent('onboarding_question_view', {
        question_id: questions[step].id,
        question_text: questions[step].question,
      });
    }
  }, [step, questions]);

  const fetchQuestions = async () => {
    try {
      const { data, error } = await supabase
        .from('onboarding_questions')
        .select('*')
        .order('order_num', { ascending: true });

      if (error) throw error;
      
      setQuestions(data);
      initializeResponses(data);
    } catch (error) {
      console.error('Error fetching questions:', error.message);
    }
  };

  const initializeResponses = (questions) => {
    const initialResponses = {};
    questions.forEach(question => {
      initialResponses[question.id] = question.type === 'input' ? {} : [];
    });
    setResponses(initialResponses);
  };
  const handleOptionToggle = (questionId, option) => {
    setResponses(prevResponses => {
      const newResponses = { ...prevResponses };
      const currentQuestion = questions.find(q => q.id === questionId);
      
      if (currentQuestion.multiple) {
        const questionResponses = [...(newResponses[questionId] || [])];
        const optionIndex = questionResponses.findIndex(r => r.id === option.id);
        
        if (optionIndex > -1) {
          questionResponses.splice(optionIndex, 1);
        } else {
          questionResponses.push({ id: option.id, text: option.text, emoji: option.emoji });
        }
        
        newResponses[questionId] = questionResponses;
      } else {
        newResponses[questionId] = [{ id: option.id, text: option.text, emoji: option.emoji }];
      }

      // Check if Kuwait is selected (assuming Kuwait's option ID is 1)
      if (questionId === 1 && option.id === 1) {
        setShowModal(true);
      }

      return newResponses;
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (questionId, fieldId, value) => {
    setResponses(prevResponses => ({
      ...prevResponses,
      [questionId]: {
        ...prevResponses[questionId],
        [fieldId]: value
      }
    }));

    // Email validation
    if (fieldId === 'email') {
      if (!validateEmail(value)) {
        setEmailError('Please enter a valid email address');
      } else {
        setEmailError('');
      }
    }
  };

  const saveResponses = async () => {
    try {
      const { data, error } = await supabase
        .from('onboarding_responses')
        .insert({
          responses: responses,
        });
      if (error) throw error;
      
      logEvent('onboarding_responses_saved', { success: true });

      
      Toast.show({
        type: 'success',
        text1: 'Success',
        text2: "Welcome to Kuwaiti Words! We're excited to have you join us.",
        position: 'bottom',
        style: {
          borderLeftColor: colors.primary,
        }
      });
    } catch (error) {
      console.error('Error saving responses:', error.message);
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'Failed to save your responses. Please try again.',
        position: 'bottom',
        style: {
          borderLeftColor: colors.darkRed,
        }
      });
    }
  };

  const handleNext = () => {
    if (step < questions.length - 1) {
      logEvent('onboarding_next_step', { current_step: step, next_step: step + 1 });
      setStep(step + 1);
    } else {
      logEvent('onboarding_completed', {});
      saveResponses();
      onFinish();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

const isCurrentQuestionAnswered = () => {
  const currentQuestion = questions[step];
  if (!currentQuestion) return false;

  if (currentQuestion.type === "input") {
    // Make inputs optional for the last step
    if (step === questions.length - 1) return true;

    const inputResponses = responses[currentQuestion.id];
    
    return currentQuestion.options.every(option => {
      const value = inputResponses[option.label_id];
      if (!value || value.trim() === '') return false;
      
      // Additional check for email validation
      if (option.label_id === 'email') {
        return validateEmail(value) && !emailError;
      }
      
      return true;
    });
  }
  return responses[currentQuestion.id] && responses[currentQuestion.id].length > 0;
};

  const handleSkip = () => {
    logEvent('onboarding_skipped', {});
    onFinish();
  };

  const renderQuestion = () => {
    const currentQuestion = questions[step];
    if (!currentQuestion) return null;

    if (currentQuestion.type === "input") {
      return (
        <View style={styles.questionContainer}>
          <Text style={styles.question}>{currentQuestion.question}</Text>
          {currentQuestion.options.map((field) => (
            <View key={field.label_id} style={styles.inputContainer}>
              <Text style={styles.inputLabel}>
                {field.label}{' '}
                <Text style={styles.optionalText}>(optional)</Text>
              </Text>
              <TextInput
                style={[
                  styles.input,
                  field.label_id === 'email' && emailError ? styles.inputError : null,
                  Platform.OS === 'web' && styles.webInput
                ]}
                placeholder={field.placeholder}
                value={responses[currentQuestion.id][field.label_id] || ''}
                onChangeText={(text) => handleInputChange(currentQuestion.id, field.label_id, text)}
                keyboardType={field.label_id === 'email' ? 'email-address' : 'default'}
              />
              {field.label_id === 'email' && emailError ? (
                <Text style={styles.errorText}>{emailError}</Text>
              ) : null}
            </View>
          ))}
        </View>
      );
    }
    return (
      <View style={styles.questionContainer}>
        <Text style={styles.question}>{currentQuestion.question}</Text>
        {currentQuestion.options.map((option) => (
          <TouchableOpacity
            key={option.id}
            style={[
              styles.optionButton,
              responses[currentQuestion.id]?.some(r => r.id === option.id) && styles.optionButtonSelected
            ]}
            onPress={() => handleOptionToggle(currentQuestion.id, option)}
          >
            <Text style={styles.optionEmoji}>{option.emoji}</Text>
            <Text style={[
              styles.optionText,
              responses[currentQuestion.id]?.some(r => r.id === option.id) && styles.optionTextSelected
            ]}>
              {option.text}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  };

  const renderHeader = () => (
    <View style={styles.header}>
      {step > 0 && (
        <TouchableOpacity onPress={handleBack} style={styles.backButton}>
          <Ionicons name="chevron-back" size={24} color={colors.primary} />
        </TouchableOpacity>
      )}
      <Image
        source={require('../../assets/dark-logo.png')}
        style={styles.headerLogo}
        resizeMode="contain"
      />
      <TouchableOpacity onPress={handleSkip} style={styles.skipButton}>
        <Text style={styles.skipButtonText}>Skip</Text>
      </TouchableOpacity>
    </View>
  );

  const renderProgressBar = () => (
    <View style={styles.progressBarContainer}>
      <View style={[styles.progressBar, { width: `${((step + 1) / questions.length) * 100}%` }]} />
    </View>
  );

  if (showWelcome) {
    return <WelcomeScreen setShowWelcome={setShowWelcome} />;
  }

  return (
    <SafeAreaView style={styles.container}>
      {renderHeader()}
      {renderProgressBar()}
      <ScrollView contentContainerStyle={styles.scrollContent}>
        {renderQuestion()}
      </ScrollView>
      <TouchableOpacity 
        style={[
          styles.continueButton, 
          isLargeScreen && styles.continueButtonLarge,
          !isCurrentQuestionAnswered() && styles.continueButtonDisabled
        ]} 
        onPress={handleNext}
        disabled={!isCurrentQuestionAnswered()}
      >
        <Text style={styles.buttonText}>Continue</Text>
      </TouchableOpacity>
      <ContributeModal
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
      <Toast />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.secondary,
    fontFamily: 'KhebratMusamim',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    height: 60,
  },
  backButton: {
    position: 'absolute',
    left: 20,
  },
  skipButton: {
    position: 'absolute',
    right: 20,
  },
  skipButtonText: {
    color: colors.primary,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'KhebratMusamim',
  },
  headerLogo: {
    width: 250,
    height: 250,
  },
  progressBarContainer: {
    height: isLargeScreen ? 8 : 4,
    backgroundColor: '#D3D3D3',
    width: '100%',
  },
  progressBar: {
    height: isLargeScreen ? 8 : 4,
    borderRadius: 2,
    backgroundColor: colors.primary,
  },
  scrollContent: {
    flexGrow: 1,
    padding: 20,
  },
  welcomeContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: colors.secondary,
  },
  welcomeTextContainer: {
    marginTop: -100,
  },
  logo: {
    width: 400,
    height: 300,
    // marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary,
    // marginBottom: 10,
    textAlign: 'center',
    fontFamily: 'KhebratMusamim',
  },
  description: {
    fontSize: 16,
    color: colors.primary,
    textAlign: 'center',
    marginBottom: 30,
    fontFamily: 'KhebratMusamim',
  },

  progressIndicator: {
    height: '100%',
    backgroundColor: colors.primary,
    borderRadius: 2,
  },
  questionContainer: {
    marginBottom: 20,
    maxWidth: isLargeScreen ? 600 : '100%',
    width: '100%',
    alignSelf: 'center',
  },
  question: {
    fontSize: 28,
    fontWeight: 'bold',
    color: colors.text,
    marginBottom: 20,
    fontFamily: 'KhebratMusamim',
  },
  optionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 12,
    padding: 15,
    marginBottom: 10,
    borderWidth: 2,
    borderColor: colors.primary,
    borderBottomWidth: 4,
    fontFamily: 'KhebratMusamim',
  },
  optionButtonSelected: {
    backgroundColor: colors.primary,
  },
  optionEmoji: {
    fontSize: 24,
    marginRight: 15,
  },
  optionText: {
    fontSize: 18,
    color: colors.black,
    fontFamily: 'KhebratMusamim',
  },
  optionTextSelected: {
    color: colors.white,
  },
  button: {
    backgroundColor: colors.primary,
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderRadius: 25,
    fontFamily: 'KhebratMusamim',
  },
  continueButton: {
    backgroundColor: colors.primary,
    paddingVertical: 15,
    borderRadius: 12,
    margin: 20,
    fontFamily: 'KhebratMusamim',
  },
  continueButtonLarge: {
    maxWidth: 600,
    width: '100%',
    alignSelf: 'center',
  },
  continueButtonDisabled: {
    backgroundColor: '#D3D3D3',
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'KhebratMusamim',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80%',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
    color: colors.primary,
    fontFamily: 'KhebratMusamim',
  },
  modalText: {
    fontSize: 16,
    marginBottom: 15,
    textAlign: 'center',
    color: colors.text,
    fontFamily: 'KhebratMusamim',
  },
  input: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 10,
    padding: 10,
    width: '100%',
    marginBottom: 15,
    fontFamily: 'KhebratMusamim',
  },
  modalButton: {
    backgroundColor: colors.primary,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 10,
  },
  modalCloseButton: {
    marginTop: 10,
  },
  modalCloseButtonText: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: 'KhebratMusamim',
  },
  modalInput: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 10,
    padding: 10,
    width: '100%',
    marginBottom: 15,
    fontFamily: 'KhebratMusamim',
  },
  inputContainer: {
    marginBottom: 15,
  },
  inputLabel: {
    fontSize: 16,
    color: colors.gray,
    marginBottom: 5,
    fontFamily: 'KhebratMusamim',
  },
  input: {
    borderWidth: 2,
    borderColor: colors.primary,
    borderRadius: 12,
    padding: 15,
    fontSize: 18,
    color: colors.black,
    fontFamily: 'KhebratMusamim',
  },
  inputError: {
    borderColor: colors.darkRed,
  },
  errorText: {
    color: colors.darkRed,
    fontSize: 14,
    marginTop: 5,
    fontFamily: 'KhebratMusamim',
  },
  webInput: {
    outlineWidth: 0,
    outlineColor: 'transparent',
    outlineStyle: 'none',
  },
  optionalText: {
    color: colors.gray,
    fontSize: 14,
    fontStyle: 'italic',
  },
});

export default OnboardingScreen;
