import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../theme/colors';

const PackCard = ({ title, category, count, onPress }) => {
  return (
    <TouchableOpacity activeOpacity={0.8}  style={styles.card} onPress={onPress}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.infoContainer}>
        <MaterialIcons name="category" size={25} color={colors.text.secondary} />
        <Text style={styles.info}>{category}</Text>
      </View>
      <View style={styles.infoContainer}>
        <MaterialIcons name="format-list-numbered" size={25} color={colors.text.secondary} />
        <Text style={styles.info}>{count} {count === 1 ? 'item' : 'items'}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: colors.primary,
    borderRadius: 10,
    padding: 20,
    width: '100%',
    height: '70%',
    justifyContent: 'center',
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  title: {
    fontFamily: 'KhebratMusamim',
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.text.secondary,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  info: {
    fontFamily: 'KhebratMusamim',
    marginLeft: 5,
    fontSize: 25,
    color: colors.text.secondary,
  },
});

export default PackCard;